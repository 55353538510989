.about {
    flex-direction: column;
    margin-top: 3em;
  }
  .about__name {
    color: var(--clr-primary);
  }
  .about > :nth-child(2) {
    margin-top: 1.2em;
    font-size: 2rem;
    line-height: 1.2;
    color: var(--clr-fg-alt);
  }
  
  .about__desc {
    font-size: 1rem;
    max-width: 70%;
  }
  
  .about__desc,
  .about__contact {
    margin-top: 2.4em;
  }
  
  .about .link--icon {
    margin-right: 0.8em;
  }
  
  .about .btn--outline {
    margin-right: 1em;
  }
  
  @media (max-width: 600px) {
    .app .about {
      align-items: flex-start;
      margin-top: 2em;
      justify-content: center;
      align-items: center;
    }
    .about > :nth-child(2) {
      font-size: 1.5rem;
    }
  }